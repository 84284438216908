// FIXME: @types/swiper does not work, temp dummy class & type to allow compilation
declare class Swiper {
    constructor(key: string, options: SwiperOptions)
    on: (event: string, handler: () => void) => void
    slideToLoop: (index: number, duration: number) => void
    realIndex: number
};
declare type SwiperOptions = object;

var swipers: {[id: string]: Swiper} = {};

var defaultSwiperSettings = {
    slidesPerView: 1,
    centerInsufficientSlides: true,
    spaceBetween: 20,
    breakpoints: {
        641: {
            slidesPerView: 2
        },
        1025: {
            slidesPerView: 4
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};

var noSwiperNavigation = {
    navigation: {},
};

var swiperLoop = {
    loop: true,
};

var addSwiperEntry: (key: string, settings: SwiperOptions, eventHandlers?: {[event: string]: () => void}) => void;

function swiperComponent() {
    requireModule('swiper', function() {

        addSwiperEntry = (key: string, settings: SwiperOptions, eventHandlers: {[event: string]: () => void} = {}) => {
            if (exists(key) && not(swipers[key])) {
                swipers[key] = new Swiper(key, settings);
        
                for (let [event, handler] of Object.entries(eventHandlers)) {
                    swipers[key].on(event, handler);
                }
            }
        }

        // BEGIN: Custom swipers
    
        addSwiperEntry('#images-swiper', {
            ...swiperLoop,
            slidesPerView: 1,
        },{'transitionEnd': function() {
            const index = (swipers['#images-swiper'].realIndex >> 0) + 1;
            const currentThumbnail = $('.product-thumbnail').toArray().filter(thumbnail => $(thumbnail).data('index') == index)[0];
            $('.product-thumbnail').not($(currentThumbnail)).removeClass('active');
            $(currentThumbnail).addClass('active');
        }})
       
        addSwiperEntry('#mobile-images-swiper', {
            ...swiperLoop,
            // breakpoints: {
                // 1024: {
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                    },
                // },
            // },
            slidesPerView: 1,
        },{'transitionEnd': function() {
            const index = (swipers['#images-swiper'].realIndex >> 0) + 1;
            const currentThumbnail = $('.product-thumbnail').toArray().filter(thumbnail => $(thumbnail).data('index') == index)[0];
            $('.product-thumbnail').not($(currentThumbnail)).removeClass('active');
            $(currentThumbnail).addClass('active');
        }})
        
        addSwiperEntry('#news-swiper', {
            // ...swiperLoop,
            slidesPerView: 1.1,
            spaceBetween: 10,
            centeredSlides: true,
            autoplay: {
                delay: 2500,
            },
            dots: true,
            initialSlide: 1,
            navigation: false,
            arrows: false,
            breakpoints: {
                640:{
                    slidesPerView: 1.5
                },
                1440: { 
                    slidesPerView: 3,
                    autoplay: false,
                    loop: false,
                    enabled: false
                }
            }
        })
        
        addSwiperEntry('#home-products-swiper', {
            spaceBetween: 10,
            dots: true,
            initialSlide: 1,
            navigation: false,
            arrows: false,
            slidesPerView: 1.2,
            autoplay: {
                delay: 2000,
            },
            breakpoints: {
                640: {
                    slidesPerView: 2.2
                },
                1025: {
                    slidesPerView: 4
                }
            },
            
        })
        
        addSwiperEntry('#eleven-worlds-swiper', {
            ...swiperLoop,
            slidesPerView: 1.2,
            // spaceBetween: 10,
            centeredSlides: true,
            autoplay: {
                delay: 2500,
            },
            dots: true,
            initialSlide: 1,
            navigation: false,
            arrows: false,
            breakpoints: {
                640:{
                    slidesPerView: 2.5
                },
            }
        })

        addSwiperEntry('#main-slider', {
            ...swiperLoop,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                bulletClass: 'swiper-bullet',
                bulletActiveClass: 'swiper-bullet-active'
            }, 
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },
            autoplay: {
                delay: 5000,
            },
            slidesPerView: 1,
        })
    
        // END: Custom swipers
    
        const uninitializedSwipers = $('.swiper-container').not('.swiper-container-initialized').toArray();
        
        for (let [index, swiper] of indexValuePairs(uninitializedSwipers)) {
            const id = $(swiper).attr('id');
    
            if (not(id)) {
                swiper.id = `generic-swiper-${index}`;
                addSwiperEntry(`#${swiper.id}`, defaultSwiperSettings);
            }
            else if (not(swipers[id])) {
                addSwiperEntry(`#${id}`, defaultSwiperSettings);
            }
        }
    });
}