declare var dataLayer: {}[];

function newsletterComponent() {
    $('.form-landing').closest('.form').parent().append('<div class="loader" style="display: none;"/>');
    $('.subscribe-newsletter').validate();
    loadCaptcha();
}


function subscribeNewsletterCaptcha() {
    const $form = $('.subscribe-newsletter');
    const checkbox = $('#checkbox3');

    if ($form.valid()) {
        const inputs = $form.find(':input').not(':disabled');
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function () {
                inputs.prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_NEWSLETTER_COMPLETED']);
                }
                else {
                    showMessageBox('danger', msg['MSG_USER_ALREADY_REGISTERED']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function () {
                inputs.prop('disabled', false);
                grecaptcha.reset()
            }
        });
    }
    else if (!checkbox.is(':checked')) {
        showMessageBox('danger', msg['MSG_PRIVACY_POLICY']);
    }
    else {
        showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
    }
}

function expiredNewsletterCaptcha() {
    $('#newsletter-subscribe button[type="submit"]').hide(400);
}

if (!checkCookie("bannerShown") && $('#newsletter-popup').length > 0) {
    if (!(window.location.href.includes('checkout') || window.location.href.includes('login'))) {
        setTimeout(() => {
            blockPageScroll();
            $("#newsletter-popup-close").on("click", (event) => {
                event.preventDefault();
                restorePageScroll();
                $("#newsletter-popup").addClass("hide");
                createCookie("bannerShown", "true");
            });
            $("#newsletter-popup img").on("click", (event) => {
                event.preventDefault();
                createCookie("bannerShown", "true");
                restorePageScroll();
                $("#newsletter-popup").addClass("hide");
                window.location.href = $("#newsletter-popup a.btn").attr("href");
            });
            $("#newsletter-popup a.btn").on('click', function() {
                $("#newsletter-popup").addClass("hide");
                createCookie("bannerShown", "true");
            });
            $("#newsletter-popup").removeClass("hide");
        }, 15000);
    }
}