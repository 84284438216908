var skin = "/skins/bmartigrafiche";

setResponsive({"mobile": 640, "tablet": 1366, "desktop": Infinity})

$(function () {
    if (exists("#home-container")) {
        homePage();
    }
    else if (exists("#cart-container")) {
        cartPage();
    }
    else if (exists("#catalog-container")) {
        catalogPage();
    }
    else if (exists("#checkout-container")) {
        checkoutPage();
    }
    else if (exists("#product-container")) {
        productPage();
    }
    else if (exists("#profile-container")) {
        profilePage();
    }
    else if (exists("#wishlist-container")) {
        wishlistPage();
    }

    if (exists(".subscribe-container")) {
        subscribeComponent();
    }
    if (exists(".product-container")) {
        productComponent();
    }
    if (exists("header")) {
        headerComponent();
    }
    if (exists("#main-menu, #mobile-menu")) {
        menuComponent();
    }
    if (exists(".subscribe-newsletter")) {
        newsletterComponent();
    }
    if (exists("#contact-form")) {
        contactComponent();
    }
    if (exists("#stationery-form")) {
        stationeryComponent();
    }
    if (exists(".swiper-container")) {
        swiperComponent();
    }
    setupVideoEmbeds();
});

function isIOS() {
    const iOSPlatforms = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ];
    // @ts-ignore navigator.platform exists on older iOS
    const platformIsIOS = navigator.platform && iOSPlatforms.includes(navigator.platform);
    return (platformIsIOS) ||
        /* fallback to user agent sniffing */ (/iPad|iPhone|iPod/.test(navigator.userAgent)) ||
        /* iPad on iOS 13 detection */ (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}


// Other scripts to be called on every page
$(function () {
    initInputMasks();
    initForceUpperCase();
    initToggle();
    initTabs();
    initSortableTables();
    initSearch();
    initEventEmitters();
    initMultiStep();
    initLightbox();
    initTelDropdowns();
    initBackgroundImageLazyLoad();

    $('a[href*="#"]').on('click', function (event) {
        event.preventDefault();

        const href = $(this).attr('href');

        if(window.location.pathname === '/it/'){
            const hash = `#${$(this).attr('href').split('#')[1]}`;
            scrollToElement(hash).then(() => window.location.hash = hash);
        }
        else {
            window.location.href = href;
        }
        
    });

    if(window.location.hash){
        scrollToElement(window.location.hash)
    }

    $(document)
        .on('set-currency', function(event, currency) {
            event.preventDefault();
            const currentCurrency = checkCookie('currency');

            if (currency != currentCurrency) {
                createCookie('currency', currency);
                window.location.reload();
            }
        })
        .on('toggle-page-scroll', function() {
            if (pageIsScrollable()) {
                blockPageScroll();
            }
            else {
                restorePageScroll();
            }
        });
});

type GoogleTagManagerOptions = {
    event: string,
    ecommerce?: object,
    eventCallback?: (containerId?: string) => void
};

function pushGTMEventWithCallback(timeout: number, options: GoogleTagManagerOptions) {
    const dataLayer: object[] = (window as any).dataLayer ?? [];

    let callbackExecuted = false;
    dataLayer.push({
        ...options,
        ...(options.eventCallback
            ? {
                eventCallback: GTMCallback(() => {
                    if (!callbackExecuted) {
                        callbackExecuted = true;
                        options.eventCallback();
                    }
                })
            }
            : {}
        )
    });

    if (options.eventCallback) {
        setTimeout(() => {
            if (!callbackExecuted) {
                callbackExecuted = true;
                options.eventCallback();
            }
        }, timeout);
    }
}