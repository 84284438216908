function productComponent() {
    loadQtyInputs();

    $('.category-selector .link').on('click tap', toggleSlider);

    function toggleSlider() {
        if (! $(this).hasClass('selected')) {
            // Deselect & hide previously selected
            var selected = $('.category-selector').find('.selected');
            var id = selected.parent().attr('id').replace('selector__', '');
            $('#slider__' + id).addClass('hidden');
            selected.removeClass('selected');

            // Select and show newly selected
            id = $(this).parent().attr('id').replace('selector__', '');
            $('#slider__' + id).removeClass('hidden');
            $(this).addClass('selected');
        }
    }

    if ($('.product-page')[0] && $(window).width() >= 1024) {
        const breadcrumb = $('.breadcrumb');
        $('.header-sticky').addClass('sticky');
        $('.info-container').addClass('sticky').attr('style', 'top: ' + breadcrumb.offset().top + 'px;');
        $('.pictures-container').addClass('sticky').attr('style', 'top: ' + breadcrumb.offset().top + 'px;');
    
        breadcrumb.on('scroll', function() {
            if (this.scrollTop > 0) {
                $(this).slideUp();
            }
            else {
                $(this).slideDown();
            }
        });
    }
    
    $('.pictures-container .picture').on('click tap', function() {
        var index = $(this).attr('id').replace('image_thumbnail__', '');
        var imageId = '#image__' + index;
        $('html, body').animate({
            scrollTop: $(imageId).offset().top - $('.breadcrumb').offset().top
        }, 400);
    });
    
    const productImages = $('.product-image-scroller .product-image').toArray();
    
    $(window).on('scroll', function() {
        const scrollTop = $(this).scrollTop();
        for (let index = 0; index < productImages.length; index++) {
            const top = $('.product-image-scroller').offset().top + $(productImages[index]).offset().top;
            if (scrollTop < top) {
                const thumbnailId = '#image_thumbnail__' + (index + 1);
                $('.pictures-container .picture.selected').removeClass('selected');
                $(thumbnailId).addClass('selected');
                break;
            }
        }
    });
    
    $('.product-image, .picture-slide').on('click tap', function() {
        var slideId;
        if ($(this).hasClass('product-image')) {
            slideId = '#modal_slide__' + $(this).attr('id').replace('image__', '');
        }
        else {
            slideId = '#modal_slide__' + $(this).attr('id').replace('picture_slide__', '');
        }
    
        $('.product-picture-modal').addClass('show');
        $(slideId).addClass('selected');
    });
    
    $('.product-picture-modal').on('click tap', function() {
        $('.product-picture-modal').removeClass('show');
        $('.modal-slide').removeClass('selected');
    });
    
    $('.modal-arrow-left').on('click tap', function(e) {
        var prevId = '#' + $('.modal-slide.selected').removeClass('selected').attr('prev');
        $(prevId).addClass('selected');
        e.stopPropagation();
    });
    
    $('.modal-arrow-right').on('click tap', function(e) {
        var nextId = '#' + $('.modal-slide.selected').removeClass('selected').attr('next');
        $(nextId).addClass('selected');
        e.stopPropagation();
    });

    $('.shipping-info-title').on('click', function() {
        $(this).closest('.shipping-info').toggleClass('open');
        $(this).siblings('.shipping-info-body').slideToggle();
    });

    $('.addtocart-form')
        .on('submit', function(event) {
            event.preventDefault();

            const $form = $(this);
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function() {
                    $form.find('input').prop('disabled', true);
                },
                success: function(data) {
                    if (data.success) {
                        reloadIcons(['#cart-icon', '#cart-icon-mobile'])
                        showMessageBox('success', msg['MSG_ADDEDTOCART']);
                        $form.find('.quantity-input').each(function() {
                            const value = $(this).val();
                            $(this).data('qtyorig', value);
                        });
                        const insertedModelId = $form.find('.quantity-input').attr('name').split('qty_model_')[1];
                        const insertedModelQuantity = $form.find('.quantity-input').val();
                        const insertedModelAvailability = $form.find('.quantity-input').data("max");
                        var modelsQuantites = $form.find('.quantity-input').data('model-quantities');
                        var modelsAvailability = $form.find('.quantity-input').data('model-availability');
                        modelsQuantites[insertedModelId] = parseInt(insertedModelQuantity.toString());
                        modelsAvailability[insertedModelId] = parseInt(insertedModelAvailability.toString());
                        $form.find('.quantity-input').data('model-quantities', modelsQuantites).data('model-availability', modelsAvailability);
                        rewixUpdateCart($form);
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function() {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function() {
                    $form.find('input').prop('disabled', false);
                    $form.trigger('change');
                }
            });
        });

        $('.add-to-wishlist, .remove-from-wishlist')
            .on('click', function(event) {
                event.stopPropagation();
            })
            .on('submit', function(event) {
                event.preventDefault();

                const $form = $(this);
                $.ajax({
                    method: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',
                    success: function(data) {
                        if (data.success) {
                            if (!$form.hasClass('wishlist-page')) {
                                reloadIcons(['#wishlist-icon', '#wishlist-icon-mobile'])
                                $form.addClass('hide')
                            }
                            if ($form.is('.add-to-wishlist')) {
                                $form.closest('.product-container').find('.remove-from-wishlist').removeClass('hide');
                                showMessageBox('success', msg['MSG_ADDEDTOWISHLIST']);
                                const dataLayer = window.dataLayer as object[];
                                const modelId = $form.find('[name*="qty_model"]').attr('name').replace('qty_model_', '');
                                if (dataLayer && modelId) {
                                    dataLayer.push({ ecommerce: null });
                                    dataLayer.push({
                                        event: "add_to_wishlist",
                                        ecommerce: ((window as any)['ecommerce'] as map<string, object>)[modelId]
                                    });
                                }
                            }
                            else if ($form.is('.remove-from-wishlist')){
                                $form.closest('.product-container').find('.add-to-wishlist').removeClass('hide');
                                showMessageBox('success', msg['MSG_REMOVEDFROMWISHLIST']);

                                if($form.hasClass('wishlist-page')){
                                    setTimeout(() => window.location.reload(), 500);
                                }
                            }
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    },
                    error: function() {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                });
            });

        $('.addtocart-form .options-radio .radio').on('click', function() {
            const form = $(this).closest('form');
            updateCurrentModel(this, (modelId)=>{
                const quantityInput = form.find('.quantity-input');
                const modelQuantitites = quantityInput.data('model-quantities')
                const modelAvailability = quantityInput.data('model-availability')
                const quantity = modelQuantitites[modelId];
                const availability = modelAvailability[modelId];

                form.find('[type=submit]').disabled(quantity<1);
                
                $(`[id*=product-availability-text]`).addClass('hide');
                $(`#product-availability-text-${modelId}`).removeClass('hide')

                form.closest('.product-container').find('.product-price').addClass('hide')
                $(`#product-price-${modelId}`).removeClass('hide')

                quantityInput.val(quantity).data("max",availability)
            });
        });
        $('.addtocart-form .options-select').on('change', function() {
            const form = $(this).closest('form');
            updateCurrentModel(this, (modelId)=>{
                const modelQuantitites = form.find('.quantity-input').data('model-quantities')
                const quantity = modelQuantitites[modelId];
                form.find('.quantity-input').val(quantity)
            });
        });

        function reloadIcons(selctors: JQuerySelector[]) {
            $.ajax({
                method: 'GET',
                url: window.location.href,
                dataType: 'html',
                success: function (data) {
                    const response = $('<div>').append($(data));
                    for (const selector of selctors) {
                        const element = response.find(selector);
                        $(selector).replaceWith(element);
                    }
                },
            });
        }


        if(isIOS()){
            $('.product-img').addClass('ios-img')
        }

        

}   