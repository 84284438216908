var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
;
var swipers = {};
var defaultSwiperSettings = {
    slidesPerView: 1,
    centerInsufficientSlides: true,
    spaceBetween: 20,
    breakpoints: {
        641: {
            slidesPerView: 2
        },
        1025: {
            slidesPerView: 4
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};
var noSwiperNavigation = {
    navigation: {},
};
var swiperLoop = {
    loop: true,
};
var addSwiperEntry;
function swiperComponent() {
    requireModule('swiper', function () {
        addSwiperEntry = function (key, settings, eventHandlers) {
            if (eventHandlers === void 0) { eventHandlers = {}; }
            if (exists(key) && not(swipers[key])) {
                swipers[key] = new Swiper(key, settings);
                for (var _i = 0, _a = Object.entries(eventHandlers); _i < _a.length; _i++) {
                    var _b = _a[_i], event_1 = _b[0], handler = _b[1];
                    swipers[key].on(event_1, handler);
                }
            }
        };
        // BEGIN: Custom swipers
        addSwiperEntry('#images-swiper', __assign(__assign({}, swiperLoop), { slidesPerView: 1 }), { 'transitionEnd': function () {
                var index = (swipers['#images-swiper'].realIndex >> 0) + 1;
                var currentThumbnail = $('.product-thumbnail').toArray().filter(function (thumbnail) { return $(thumbnail).data('index') == index; })[0];
                $('.product-thumbnail').not($(currentThumbnail)).removeClass('active');
                $(currentThumbnail).addClass('active');
            } });
        addSwiperEntry('#mobile-images-swiper', __assign(__assign({}, swiperLoop), { 
            // breakpoints: {
            // 1024: {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            }, 
            // },
            // },
            slidesPerView: 1 }), { 'transitionEnd': function () {
                var index = (swipers['#images-swiper'].realIndex >> 0) + 1;
                var currentThumbnail = $('.product-thumbnail').toArray().filter(function (thumbnail) { return $(thumbnail).data('index') == index; })[0];
                $('.product-thumbnail').not($(currentThumbnail)).removeClass('active');
                $(currentThumbnail).addClass('active');
            } });
        addSwiperEntry('#news-swiper', {
            // ...swiperLoop,
            slidesPerView: 1.1,
            spaceBetween: 10,
            centeredSlides: true,
            autoplay: {
                delay: 2500,
            },
            dots: true,
            initialSlide: 1,
            navigation: false,
            arrows: false,
            breakpoints: {
                640: {
                    slidesPerView: 1.5
                },
                1440: {
                    slidesPerView: 3,
                    autoplay: false,
                    loop: false,
                    enabled: false
                }
            }
        });
        addSwiperEntry('#home-products-swiper', {
            spaceBetween: 10,
            dots: true,
            initialSlide: 1,
            navigation: false,
            arrows: false,
            slidesPerView: 1.2,
            autoplay: {
                delay: 2000,
            },
            breakpoints: {
                640: {
                    slidesPerView: 2.2
                },
                1025: {
                    slidesPerView: 4
                }
            },
        });
        addSwiperEntry('#eleven-worlds-swiper', __assign(__assign({}, swiperLoop), { slidesPerView: 1.2, 
            // spaceBetween: 10,
            centeredSlides: true, autoplay: {
                delay: 2500,
            }, dots: true, initialSlide: 1, navigation: false, arrows: false, breakpoints: {
                640: {
                    slidesPerView: 2.5
                },
            } }));
        addSwiperEntry('#main-slider', __assign(__assign({}, swiperLoop), { pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                bulletClass: 'swiper-bullet',
                bulletActiveClass: 'swiper-bullet-active'
            }, 
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },
            autoplay: {
                delay: 5000,
            }, slidesPerView: 1 }));
        // END: Custom swipers
        var uninitializedSwipers = $('.swiper-container').not('.swiper-container-initialized').toArray();
        for (var _i = 0, _a = indexValuePairs(uninitializedSwipers); _i < _a.length; _i++) {
            var _b = _a[_i], index = _b[0], swiper = _b[1];
            var id = $(swiper).attr('id');
            if (not(id)) {
                swiper.id = "generic-swiper-".concat(index);
                addSwiperEntry("#".concat(swiper.id), defaultSwiperSettings);
            }
            else if (not(swipers[id])) {
                addSwiperEntry("#".concat(id), defaultSwiperSettings);
            }
        }
    });
}
